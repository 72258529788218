.button {
  background-color: #F9B332;
  font-size: 22px;
  line-height: 1.36363636;
  font-family: inherit;
  cursor: pointer;
  color: #0C3358;
  padding: 10px 30px 10px 52px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  transition: all 0.24s;
}
.button:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 52px;
  height: 100%;
  background-color: #0C3358;
  mask-size: auto 18px;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/chevron-right.svg);
  transition: all 0.24s;
}
.button:hover,
.button:focus {
  background-color: #0C3358;
  color: #F9B332;
}
.button:hover:before,
.button:focus:before {
  mask-position: 60% 50%;
  background-color: #F9B332;
}
@media (max-width: 767px) {
  .button {
    font-size: 18px;
    line-height: 1.44444444;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.area--one {
  width: calc(100% + 60px);
  margin-left: -30px;
}
.area--one .unit {
  margin-left: 30px;
  margin-right: 30px;
  width: calc(100% - 60px);
}
.area--one .unitOne--1-2 {
  width: calc(50% - 60px);
}
.area--one .unitTwo {
  width: calc((100%/1124*426) - 60px);
}
.area--four {
  width: calc(100% + 60px);
  margin-left: -30px;
}
.area--four .unit {
  margin-left: 30px;
  margin-right: 30px;
  width: calc(100% - 60px);
}
.area--four .unitOne--1-3 {
  width: calc((100% / 3) - 60px);
}
.area--four .unitThree {
  width: calc(50% - 60px);
}
/*# sourceMappingURL=./screen-medium.css.map */